import _Object$entries from 'babel-runtime/core-js/object/entries';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import _Object$create from 'babel-runtime/core-js/object/create';
import _Object$getPrototypeOf from 'babel-runtime/core-js/object/get-prototype-of';
import { camel as ccCamel, snake as ccSnake, header as ccHeader } from 'change-case';
import { isPlainObject, isURLSearchParams, isFormData } from './util';

var transform = function transform(data, fn) {
  var overwrite = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!Array.isArray(data) && !isPlainObject(data) && !isFormData(data) && !isURLSearchParams(data)) {
    return data;
  }

  /* eslint-disable no-console */
  if (isFormData(data) && !data.entries) {
    if (navigator.product === 'ReactNative') {
      console.warn('Be careful that FormData cannot be transformed on React Native. If you intentionally implemented, ignore this kind of warning: https://facebook.github.io/react-native/docs/debugging.html');
    } else {
      console.warn('You must use polyfill of FormData.prototype.entries() on Internet Explorer or Safari: https://github.com/jimmywarting/FormData');
    }
    return data;
  }
  /* eslint-enable no-console */

  var prototype = _Object$getPrototypeOf(data);
  var store = overwrite ? data : prototype ? new prototype.constructor() : _Object$create(null);
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = _getIterator(prototype && prototype.entries ? prototype.entries.call(data) : _Object$entries(data)), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var _ref = _step.value;

      var _ref2 = _slicedToArray(_ref, 2);

      var key = _ref2[0];
      var value = _ref2[1];

      if (prototype && prototype.append) {
        prototype.append.call(store, key.replace(/[^[\]]+/g, function (k) {
          return fn(k);
        }), transform(value, fn));
      } else if (key !== '__proto__') {
        store[fn(key)] = transform(value, fn);
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return store;
};

export var createTransform = function createTransform(fn) {
  return function (data) {
    var overwrite = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return transform(data, fn, overwrite);
  };
};

export var snake = createTransform(ccSnake);
export var camel = createTransform(ccCamel);
export var header = createTransform(ccHeader);

export default transform;