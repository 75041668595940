import React, { useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import get from 'lodash/get';
import moment from 'moment';
import { isCqg } from '../../utils/methods';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import EnvPromise from '../../config/env';
import useIsFCM from '../../hooks/useIsFCM';

const Agreements = ({ user }) => {
  const [agreementPdfEndpoint, setAgreementPdfEndpoint] = useState('');
  const isFCM = useIsFCM();

  useEffect(() => {
    EnvPromise.then(({ agreementPdfEndpoint }) =>
      setAgreementPdfEndpoint(agreementPdfEndpoint),
    );
  }, []);

  return (
    <List style={{ margin: '0px', fontSize: '1.1em' }}>
      {isCqg(user.defaultTradingApp) && (
        <>
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.TRADE_PROFILE}`]}
          >
            {!isFCM && (
              <>
                <List.Item>
                  <List.Icon name="exchange" />{' '}
                  {`CQG Username: ${get(
                    user,
                    'cqgUsername',
                    'No CQG Username found.',
                  )}`}
                </List.Item>
                <List.Item>
                  <List.Icon name="exchange" />{' '}
                  {`CQG Trader Id: ${get(
                    user,
                    'cqgUserId',
                    'No CQG Trader Id found.',
                  )}`}
                </List.Item>
              </>
            )}
          </AccessControl>
        </>
      )}
      {has(user, 'agreementAcceptedTime') ? (
        <List.Item>
          <List.Icon name="file outline" />
          <List.Content>
            <a
              href={agreementPdfEndpoint}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cboe Digital Member Master Agreement
            </a>
            :
            <span>
              {' '}
              Accepted on {moment(user.agreementAcceptedTime).format('LLLL')}
            </span>
          </List.Content>
        </List.Item>
      ) : null}
    </List>
  );
};

Agreements.propTypes = {
  user: PropTypes.shape({
    defaultTradingApp: PropTypes.string,
    agreementAcceptedTime: PropTypes.string.isRequired,
  }),
};

Agreements.defaultProps = {
  user: {
    defaultTradingApp: '',
    agreementAcceptedTime: '',
  },
};

export default Agreements;
