"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.floatsOnly = exports.numbersOnly = exports.formatPhone = void 0;

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var formatPhone = function formatPhone(value) {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return "".concat(onlyNums.slice(0, 3), "-").concat(onlyNums.slice(3));
  }

  return "".concat(onlyNums.slice(0, 3), "-").concat(onlyNums.slice(3, 6), "-").concat(onlyNums.slice(6, 10));
};

exports.formatPhone = formatPhone;

var numbersOnly = function numbersOnly(number) {
  return number ? number.replace(/[^\d]/g, '') : '';
}; // Allow fractional component to be represented with a decimal point
// Handles multiple periods correctly by only allowing the first one.


exports.numbersOnly = numbersOnly;

var floatsOnly = function floatsOnly(number) {
  var scrubbed = number ? number.replace(/[^\d.-]/g, '') : '';

  var _scrubbed$split = scrubbed.split('.'),
      _scrubbed$split2 = (0, _toArray2["default"])(_scrubbed$split),
      integer = _scrubbed$split2[0],
      fractional = _scrubbed$split2.slice(1);

  return "".concat(integer).concat(fractional.length ? ".".concat(fractional.join('')) : '');
};

exports.floatsOnly = floatsOnly;