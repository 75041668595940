"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getColorFromUserState = void 0;

/**
 * Helper util to color-code user onboarding
 * @param {string} state: user onboarding state
 */
var getColorFromUserState = function getColorFromUserState(state) {
  switch (state) {
    case 'ineligible_pii':
      return 'red';

    case 'kba1':
    case 'kba2':
      return 'olive';

    case 'onboarded':
      return 'green';

    default:
      return 'yellow';
  }
};

exports.getColorFromUserState = getColorFromUserState;