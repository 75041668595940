import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Sidebar, Icon, Segment } from 'semantic-ui-react';
import Swipeable from 'react-swipeable';
import { hideSidebar } from '../reducers/sidebarReducer';
/** Sidebar Components */
import DepositWithdrawalContainer from './FundTransfers/DepositWithdrawalContainer';
import VerticalNavContainer from '../common/containers/VerticalNavContainer';

/** Sidebar Type Constants */
import {
  DEPOSIT_WITHDRAWAL_CONTAINER,
  ORDER_ENTRY_CONTAINER,
  VERTICAL_NAV_CONTAINER,
} from '../constants/sideBarTypes';
import { isViewportLessThan } from '../reducers/uiReducer';
import OrderEntryWidgetContainer from './OrderEntry/OrderEntryWidgetContainer';

const SIDEBAR_COMPONENTS = {
  [DEPOSIT_WITHDRAWAL_CONTAINER]: DepositWithdrawalContainer,
  [VERTICAL_NAV_CONTAINER]: VerticalNavContainer,
  [ORDER_ENTRY_CONTAINER]: OrderEntryWidgetContainer,
};

const SidebarContainer = (props) => {
  // initialize SidebarContent to an empty div, this is needed.
  let SidebarContent = () => <div />;
  // if there's a sidebarContent state, then set that component to SidebarContent
  if (props.sidebarContent) {
    SidebarContent = SIDEBAR_COMPONENTS[props.sidebarContent];
  }
  return (
    <Swipeable
      nodeName="section"
      disabled={!props.viewportLessThanMedium}
      onSwipedLeft={props.hideSidebar}
      trackMouse
      stopPropagation
    >
      <Sidebar
        as={Segment}
        animation="overlay"
        // get slide-in animation direction from props, default to 'left'
        direction={_.get(props, ['args', 'direction'], 'left')}
        visible={!!props.sidebarContent}
        width="very wide"
        onHide={
          _.get(props, ['args', 'closeOnClick'], true)
            ? () => props.hideSidebar
            : () => {}
        }
        className="content-sidebar"
      >
        <Icon
          link
          name="close"
          onClick={props.hideSidebar}
          className="pull-right"
        />
        <div className="sidebar-content">
          <SidebarContent {...props.args} />
        </div>
      </Sidebar>
    </Swipeable>
  );
};

const mapStateToProps = (state) => ({
  ..._.get(state, ['ui', 'sidebar']),
  viewportLessThanMedium: isViewportLessThan('medium', state),
});

export default connect(mapStateToProps, { hideSidebar })(SidebarContainer);
