"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

exports.QRCode = QRCode;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var QRCodeImpl = require("qr.js/lib/QRCode");
var ErrorCorrectLevel = require("qr.js/lib/ErrorCorrectLevel");

function QRCode() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var _ref$value = _ref.value,
        value = _ref$value === undefined ? "" : _ref$value,
        _ref$level = _ref.level,
        level = _ref$level === undefined ? "L" : _ref$level,
        _ref$bgColor = _ref.bgColor,
        bgColor = _ref$bgColor === undefined ? "#FFFFFF" : _ref$bgColor,
        _ref$fgColor = _ref.fgColor,
        fgColor = _ref$fgColor === undefined ? "#000000" : _ref$fgColor,
        _ref$cellClassPrefix = _ref.cellClassPrefix,
        cellClassPrefix = _ref$cellClassPrefix === undefined ? "" : _ref$cellClassPrefix,
        otherProps = _objectWithoutProperties(_ref, ["value", "level", "bgColor", "fgColor", "cellClassPrefix"]);

    // adapted from https://github.com/zpao/qrcode.react/blob/master/src/index.js
    var qrcode = new QRCodeImpl(-1, ErrorCorrectLevel[level]);
    qrcode.addData(value);
    qrcode.make();

    var cells = qrcode.modules;

    var cellClassName = cellClassPrefix && cellClassPrefix + "-cell";
    var emptyCellClassName = cellClassPrefix && cellClassName + " " + cellClassPrefix + "-cell-empty";
    var filledCellClassName = cellClassPrefix && cellClassName + " " + cellClassPrefix + "-cell-filled";

    var cellIndex = 0; // we use simple order as a key just to avoid the key warning here

    return _react2.default.createElement(
        "svg",
        _extends({
            shapeRendering: "crispEdges",
            viewBox: [0, 0, cells.length, cells.length].join(" ")
        }, otherProps),
        cells.map(function (row, rowIndex) {
            return row.map(function (cell, colIndex) {
                var className = cell ? filledCellClassName : emptyCellClassName;
                var classNameProp = className ? { className: className } : null;

                // Only use the fill if no cellClassPrefix was set. See https://github.com/no23reason/react-qr-svg/issues/136 for reasoning.
                var fill = !classNameProp && (cell ? fgColor : bgColor);
                var styleProp = fill ? { style: { fill: fill } } : null;
                return _react2.default.createElement("rect", _extends({
                    height: 1,
                    key: cellIndex++ // string was too slow here
                }, styleProp, classNameProp, {
                    width: 1,
                    x: colIndex,
                    y: rowIndex
                }));
            });
        })
    );
}

QRCode.propTypes = {
    value: _propTypes2.default.string.isRequired,
    size: _propTypes2.default.number,
    level: _propTypes2.default.oneOf(["L", "M", "Q", "H"]),
    bgColor: _propTypes2.default.string,
    fgColor: _propTypes2.default.string,
    cellClassPrefix: _propTypes2.default.string
};