"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mdyDate = exports.ssnMatch = exports.email = exports.zipcode = exports.number = exports.exactlength = exports.maxLength = exports.warning = exports.required = void 0;

var required = function required(value) {
  return value ? undefined : 'This field is required';
};

exports.required = required;

var warning = function warning(value) {
  return value ? undefined : 'This field is recommended.';
};

exports.warning = warning;

var maxLength = function maxLength(max) {
  return function (value) {
    return value && value.length > max ? "Must be ".concat(max, " characters or less") : undefined;
  };
};

exports.maxLength = maxLength;

var exactlength = function exactlength(length) {
  return function (value) {
    return value && value.length === length ? "Must be ".concat(length, " characters") : undefined;
  };
};

exports.exactlength = exactlength;

var number = function number(value) {
  return value && isNaN(Number(value)) ? 'Must be a number' : undefined;
};

exports.number = number;

var zipcode = function zipcode(value) {
  return value && !/^\d{5}(?:[-\s]\d{4})?$/i.test(value) ? 'Invalid zip code' : undefined;
};

exports.zipcode = zipcode;

var email = function email(value) {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
};

exports.email = email;

var ssnMatch = function ssnMatch(value, allValues) {
  return value && value !== allValues.ssn ? "SSN's dont match" : undefined;
};

exports.ssnMatch = ssnMatch;

var mdyDate = function mdyDate(value) {
  return value && !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value) ? 'Date must be in form "MM/DD/YYYY"' : undefined;
};

exports.mdyDate = mdyDate;