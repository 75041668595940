"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columns = void 0;
// Keep a healthy amount of padding per letter.
var CHAR_WIDTH = 12;

var chars = function chars(n) {
  return n * CHAR_WIDTH;
};

var columns = {
  FIRM_CODE: chars(4),
  ACCOUNT_CODE: chars(6),
  SUB_ACCOUNT_CODE: chars(15),
  ACCOUNT_NAME: chars(25),
  STATE: chars(2)
};
exports.columns = columns;