"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = modalReducer;
exports.hideModal = exports.showModal = exports.getAllArgs = exports.getAllModalTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _lodash = _interopRequireDefault(require("lodash"));

var _reselect = require("reselect");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//* Modal Action Types */
var SHOW_MODAL = 'SHOW_MODAL';
var HIDE_MODAL = 'HIDE_MODAL';
/** Initial State */

var initialModalState = {
  modalTypes: [],
  args: {}
};
/** Modal reducer */

function modalReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialModalState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalTypes: [].concat((0, _toConsumableArray2["default"])(state.modalTypes), [action.modalType]),
        args: _objectSpread(_objectSpread({}, state.args), {}, (0, _defineProperty2["default"])({}, action.modalType, action.args))
      };

    case HIDE_MODAL:
      return {
        modalTypes: _lodash["default"].slice(state.modalTypes, 0, -1),
        args: _lodash["default"].omit(state.args, action.modalType)
      };

    default:
      return state;
  }
} //* Selectors */


var getAllModalTypes = function getAllModalTypes(state) {
  return _lodash["default"].get(state, ['ui', 'modal', 'modalTypes'], []);
};

exports.getAllModalTypes = getAllModalTypes;

var getAllArgs = function getAllArgs(state) {
  return _lodash["default"].get(state, ['ui', 'modal', 'args'], {});
};
/**
 * Action creator to load a modal based on a modalType.
 * @param {String} modalType - The name of modal obtained from /constants/modalTypes.
 */


exports.getAllArgs = getAllArgs;

var showModal = function showModal(modalType, args) {
  return {
    type: SHOW_MODAL,
    modalType: modalType,
    args: args
  };
};
/**
 * Action generator to hide a modal.
 *  * @param {String} modalType - The name of modal obtained from /constants/modalTypes.
 */


exports.showModal = showModal;

var hideModal = function hideModal(modalType) {
  return {
    type: HIDE_MODAL,
    modalType: modalType
  };
};

exports.hideModal = hideModal;