"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _format = require("./format");

var _getColorFromUserState = require("./getColorFromUserState");

var _getFundsDesignationTextByValue = require("./getFundsDesignationTextByValue");

var filterUtils = _interopRequireWildcard(require("./filters"));

var components = _interopRequireWildcard(require("./forms/components"));

var normalization = _interopRequireWildcard(require("./forms/normalization"));

var rules = _interopRequireWildcard(require("./forms/rules"));

var _arrayToObject = _interopRequireDefault(require("./arrayToObject"));

var _calculateNotional = _interopRequireDefault(require("./calculateNotional"));

var methods = _interopRequireWildcard(require("./miscMethods"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

module.exports = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, components), filterUtils), methods), {}, {
  normalization: normalization,
  rules: rules,
  format: _format.format,
  getColorFromUserState: _getColorFromUserState.getColorFromUserState,
  getFundsDesignationTextByValue: _getFundsDesignationTextByValue.getFundsDesignationTextByValue,
  arrayToObject: _arrayToObject["default"],
  calculateNotional: _calculateNotional["default"]
});