"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_FILTER_OP = exports["default"] = void 0;
var _default = {
  email: 'contain',
  name: 'contain',
  description: 'contain',
  disabled: 'eq',
  id: 'eq',
  px: 'eq',
  qty: 'eq'
};
exports["default"] = _default;
var DEFAULT_FILTER_OP = 'contain';
exports.DEFAULT_FILTER_OP = DEFAULT_FILTER_OP;