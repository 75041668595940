module.exports = {
  "name": "bigi",
  "version": "1.4.2",
  "description": "Big integers.",
  "keywords": [
    "cryptography",
    "math",
    "bitcoin",
    "arbitrary",
    "precision",
    "arithmetic",
    "big",
    "integer",
    "int",
    "number",
    "biginteger",
    "bigint",
    "bignumber",
    "decimal",
    "float"
  ],
  "devDependencies": {
    "coveralls": "^2.11.2",
    "istanbul": "^0.3.5",
    "jshint": "^2.5.1",
    "mocha": "^2.1.0",
    "mochify": "^2.1.0"
  },
  "repository": {
    "url": "https://github.com/cryptocoinjs/bigi",
    "type": "git"
  },
  "main": "./lib/index.js",
  "scripts": {
    "browser-test": "./node_modules/.bin/mochify --wd -R spec",
    "test": "./node_modules/.bin/_mocha -- test/*.js",
    "jshint": "./node_modules/.bin/jshint --config jshint.json lib/*.js ; true",
    "unit": "./node_modules/.bin/mocha",
    "coverage": "./node_modules/.bin/istanbul cover ./node_modules/.bin/_mocha -- --reporter list test/*.js",
    "coveralls": "npm run-script coverage && node ./node_modules/.bin/coveralls < coverage/lcov.info"
  },
  "dependencies": {},
  "testling": {
    "files": "test/*.js",
    "harness": "mocha",
    "browsers": [
      "ie/9..latest",
      "firefox/latest",
      "chrome/latest",
      "safari/6.0..latest",
      "iphone/6.0..latest",
      "android-browser/4.2..latest"
    ]
  }
}
;