"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.datetimeOnchange = exports.checkboxOnChange = exports.inputOnChange = exports.sortedArrayToAttrValue = exports.filteredArrayToAttrFalsyValue = exports.filteredArrayToAttrValue = exports.filteredArrayToObject = exports.paramsToObject = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _lodash = require("lodash");

var _changeCaseObject = require("change-case-object");

var _moment = _interopRequireDefault(require("moment"));

var _filterOps = _interopRequireDefault(require("../../constants/filterOps"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// takes an iterable 'entries' and converts it to an array of 'id, value'
// objects that react-table uses for it's 'filtered' prop
var paramsToObject = function paramsToObject(entries) {
  if (entries == null || typeof entries[Symbol.iterator] !== 'function') {
    console.warn('Bad argument passed to paramsToObject');
    return [];
  }

  var result = [];

  var _iterator = _createForOfIteratorHelper(entries),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var entry = _step.value;

      // each 'entry' is a [key, value] tupple
      var _entry = (0, _slicedToArray2["default"])(entry, 2),
          id = _entry[0],
          value = _entry[1]; // check if it's a min- max- query and set the operator variable accordingly


      var op = _filterOps["default"][id] || 'match';

      if (id.search('min-') !== -1) {
        op = 'gte';
      }

      if (id.search('max-') !== -1) {
        op = 'lte';
      }

      result.push({
        id: id,
        value: value,
        op: op
      });
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return result;
}; // takes in an array of 'id, value' objects that react-table uses for it's 'filtered' prop and converts it to an object of id's as keys and values as values.
// ex. [{ id: 'account', value: 'some-account-label' }] -> { account: 'some-account-label' }


exports.paramsToObject = paramsToObject;

var filteredArrayToObject = function filteredArrayToObject(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToObject');
    return {};
  }

  return filteredArray.reduce(function (acc, val) {
    return _objectSpread(_objectSpread({}, acc), val.id && (0, _defineProperty2["default"])({}, val.id, val.value));
  }, {});
};

exports.filteredArrayToObject = filteredArrayToObject;

var filteredArrayToAttrValue = function filteredArrayToAttrValue(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToAttrValue');
    return [];
  }

  return filteredArray.filter(function (_ref2) {
    var value = _ref2.value;
    return (// filter out the empty values and remove from resulting array
      !(0, _lodash.isEmpty)(value)
    );
  }).map(function (_ref3) {
    var id = _ref3.id,
        value = _ref3.value,
        op = _ref3.op;
    // filter out the min- max- prefixes since we can't use them in the backend.
    var attr = id.replace(/((min-)|(max-))/g, '');
    return {
      attr: attr,
      value: value,
      op: op
    };
  });
};

exports.filteredArrayToAttrValue = filteredArrayToAttrValue;

var filteredArrayToAttrFalsyValue = function filteredArrayToAttrFalsyValue(filteredArray) {
  if (!Array.isArray(filteredArray)) {
    console.warn('Bad argument passed to filteredArrayToAttrValue');
    return [];
  }

  return filteredArray.filter(function (_ref4) {
    var value = _ref4.value;
    return value !== undefined;
  }).map(function (_ref5) {
    var id = _ref5.id,
        value = _ref5.value,
        op = _ref5.op;
    // filter out the min- max- prefixes since we can't use them in the backend.
    var attr = id.replace(/((min-)|(max-))/g, '');
    return {
      attr: attr,
      value: value,
      op: op
    };
  });
};

exports.filteredArrayToAttrFalsyValue = filteredArrayToAttrFalsyValue;

var sortedArrayToAttrValue = function sortedArrayToAttrValue(sortedArray) {
  if (!Array.isArray(sortedArray)) {
    console.warn('Bad argument passed to sortedArrayToAttrValue');
    return [];
  }

  return sortedArray.map(function (_ref6) {
    var _ref6$id = _ref6.id,
        id = _ref6$id === void 0 ? '' : _ref6$id,
        desc = _ref6.desc;
    return {
      attr: (0, _changeCaseObject.snakeCase)(id),
      value: desc ? 'desc' : 'asc'
    };
  });
};

exports.sortedArrayToAttrValue = sortedArrayToAttrValue;

var inputOnChange = function inputOnChange(onChange) {
  return function (e, _ref7) {
    var name = _ref7.name,
        value = _ref7.value;
    return onChange(name, value, _filterOps["default"][name] || 'match');
  };
};

exports.inputOnChange = inputOnChange;

var checkboxOnChange = function checkboxOnChange(onChange) {
  return function (e, _ref8) {
    var name = _ref8.name,
        _ref8$checked = _ref8.checked,
        checked = _ref8$checked === void 0 ? false : _ref8$checked;

    if (checked) {
      onChange(name, checked.toString(), 'eq');
    } else {
      onChange(name, '');
    }
  };
};

exports.checkboxOnChange = checkboxOnChange;

var datetimeOnchange = function datetimeOnchange(name, op) {
  return function (onChange) {
    return function (time) {
      var filter = time; // have to format the time if it's a moment object.
      // when the user selects from the date-time dropdown, you have to format it, but if the user edits
      // the input inline, it's a normal string.

      if (_moment["default"].isMoment(time)) {
        filter = time.format();
      }

      onChange(name, filter, op);
    };
  };
};

exports.datetimeOnchange = datetimeOnchange;